// Handles all user token duties, including requesting new tokens
  function userTokensFactory(Token, AuthValues, $rootScope, $q, $http,
  $interval, $log) {
    var uT = {};

    var tokenName = 'id_token'; // later set based on config from server

    var intervalPromise = null;
    // by default, set a 30 minute timeout
    // to give us confidence that long uploads have enough time to complete
    var refreshTime = 30 * 60 * 1000; // 30mim

    var idToken = new Token(tokenName);

    // I think on every get, should refresh token if expired
    Object.defineProperty(uT, 'idToken', {
      get: function() {
        return idToken.token;
      },
      set: function(data) {
        idToken.token = data;
      },
      enumerable: true,
    });

    //TODO: fix the weird duplication of functions here and service.token.js
    uT.decode = function() {
      return idToken.decode();
    };

    uT.isExpired = function() {
      return idToken.isExpired();
    };

    uT.clear = function() {
      idToken.clear();
      cancelMaintainIdToken();
    };

    uT.attachToken = function attachToken(object) {
      object[tokenName] = uT.token;
      return object;
    };

    uT.refreshIdTokenAsync = refreshIdTokenAsync;
    uT.maintainIdToken = maintainIdToken;
    uT.cancelMaintainIdToken = cancelMaintainIdToken;

    var _refreshPromise = null;

    return uT; // all angular singleton

    /*Public*/
    function refreshIdTokenAsync() {
      if (_refreshPromise) {
        return _refreshPromise;
      }

      _refreshPromise = $http.get(AuthValues.authBaseRoute + 'refresh', {
        cache: false,
        ignoreAuthModule: true,
      }).then(function(response) {
        if (!response) {
          var err = new Error('response missing in refreshIdTokenAsync');
          $log.error(err);
          return $q.reject(err);
        }

        idToken.token = response.data;
        _refreshPromise = null;

        maintainIdToken();

        return response;
      }, function(rejection) {
        _refreshPromise = null;

        cancelMaintainIdToken();

        return $q.reject(rejection);
      });

      return _refreshPromise;
    }

    
    function maintainIdToken(successCb, failureCb) {
      cancelMaintainIdToken();
      intervalPromise = $interval(function() {
        refreshIdTokenAsync().then(function(response) {
          (successCb || angular.noop)(response);
        }, function(err) {
          //TODO: this is an iife I believe, check!
          (failureCb || angular.noop)(err);
        } );
      }, refreshTime);

      return intervalPromise;
    }

    function cancelMaintainIdToken() {
      if (intervalPromise) {
        $interval.cancel(intervalPromise);
      }
    }
  }

  angular.module('sq.user.auth.tokens', 
    ['sq.services.token']).factory('userTokens', userTokensFactory);

  // no longer used, todo remove

  /* var authInterceptor = {
    request: function request(tokenName,config) 
    {
      config.headers = config.headers || {};
      if ($cookieStore.get(tokenName) ) 
      {
        config.headers.Authorization = 'Bearer ' + 
          $cookieStore.get(tokenName );
      }
      return config;
    },
    // Intercept 401s and redirect you to login
    // Todo: add revoked cookies to redis store on backend
    responseError: function responseError(response) 
    {
      if(response.status === 401) 
      {
        // remove any stale tokens
        $cookieStore.remove(AUTH_CNST.tokenName);
        return $q.reject(response);
      }
      else 
      {
        return $q.reject(response);
      }
    }
  }
  */
